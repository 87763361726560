import React, { Component } from 'react';

// import AnchorLink from 'react-anchor-link-smooth-scroll';

import Layout from '../components/layout';
import SEO from "../components/seo";

import './casestudyblackbook.scss';

import imgQuote from "../images/quotation_mark.webp";

import SimpleModal from '../components/utilities/SimpleModal';

class FullWidthVideo extends Component {
    constructor(props) {
        super(props);
        this.modalRef = React.createRef();
        this.openModal = this.openModal.bind(this);
    };
    openModal() {
        this.modalRef.current.handleOpen();
    };
    render() {
        const divStyles = {
            backgroundImage: 'url('+this.props.image+')',
            height: this.props.height
        };
        return (
            <div>
                <div className="container-fluid full-width-video-wrapper" onClick={() => this.openModal()} style={divStyles}>&nbsp;</div>
                <SimpleModal videoSRC={this.props.videoSRC} innerRef={this.modalRef}/>
            </div>
        );
    }
}

export default ({pageContext}) => (
    <Layout>
        <SEO 
            title={pageContext.yoast.title}
            description={pageContext.yoast.metadesc}
            ogimg={pageContext.yoast.opengraph_image}
            link={pageContext.link}
            />        
        <div className="case-study-one-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="header-container" style={{backgroundImage: `url(${pageContext.acf.header_image.csOneHedImaLocalFile.childImageSharp.fluid.src})`}}>
                        <img src={imgQuote} alt=""/>
                        <h2>{pageContext.acf.header_text}</h2>
                    </div>
                </div>
            </div>
            <div className="container detail-wrapper">
                <div className="row">
                    <div className="col-12">
                        <img src={pageContext.acf.client_logo.csOneCliLogLocalFile.childImageSharp.fluid.src} className="client-logo" width={pageContext.acf.client_logo.media_details.width/2} height={pageContext.acf.client_logo.media_details.height/2} alt={pageContext.yoast.opengraph_title} />
                    </div>
                    <div className="col-12">
                        <div className="bar"></div>
                    </div>
                    <div className="col-12">
                        <div className="first-paragraph" dangerouslySetInnerHTML={{ __html: pageContext.acf.first_paragraph}} />
                    </div>
                    <div className="col-12"> 
                        {pageContext.acf.learn_more_url
                            ? <a href={pageContext.acf.learn_more_url} className={pageContext.acf.learn_more_url ? '' : 'd-none'} target="_blank" rel="noopener noreferrer"><div className="btn btn-grey">Learn More</div></a>
                            : <div className="d-none"><div className="btn btn-grey">Learn More</div></div>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 order-2">
                        <div className="second-paragraph" dangerouslySetInnerHTML={{ __html: pageContext.acf.second_paragraph}} />
                        <div className="third-paragraph" dangerouslySetInnerHTML={{ __html: pageContext.acf.third_paragraph}} />
                    </div>
                    <div className="col-md-6 order-1 order-md-2">
                        <img src={pageContext.acf.desktop_image.csOneDesImaLocalFile.childImageSharp.fluid.src} className="desktop-image img-fluid" width={pageContext.acf.desktop_image.media_details.width} height={pageContext.acf.desktop_image.media_details.height} alt=""/>
                    </div>
                    <div className="col-12 order-3">
                        <div className="fourth-paragraph" dangerouslySetInnerHTML={{ __html: pageContext.acf.fourth_paragraph}} />
                    </div>
                </div>
            </div>
            <div className="container-fluid cta">
                <div className="row">
                    <div className="col-12">
                        <h2 className="ctaTitleOne">Start Creating Business Momentum Today</h2>
                        <a href="#footer" className="btn btn-light">Get In Touch</a>
                    </div>
                </div>
            </div>
            <FullWidthVideo videoSRC={pageContext.childWordPressAcfAddFullWidthVideo.youtube_id} height={pageContext.childWordPressAcfAddFullWidthVideo.background_image.media_details.height/2} image={pageContext.childWordPressAcfAddFullWidthVideo.background_image.source_url} />
            <div className="detail-wrapper">
                <div className="container single-image-text-wrapper">
                    <div className="row">
                        <div className="col-md-6 text-center">
                            <img src="https://www.meritmile.com/uploads/2018/11/black-book-content-holder.jpg" className="img-fluid" width="405.5" height="338.5" alt="" />
                        </div>
                        <div className="col-md-6 text">
                            <div className="text">
                                <h3>Deliverables</h3>
                                <ul>
                                    <li>Research Study Program Design</li>
                                    <li>Benchmark Surveys</li>
                                    <li>Recruited Focus Group</li>
                                    <li>User Testing &amp; Evaluation</li>
                                    <li>Observations &amp; Findings</li>
                                    <li>Final Recommendations with Qual/Quan Support</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container view-website-btn">
                <div className="row">
                    <div className="col-12 text-center">
                        <a href="https://www.blackbook.com/" target="_blank" rel="noopener noreferrer">
                            <div className="btn btn-black">View Website</div>
                        </a>
                    </div>
                </div>
            </div>
            <div className="container quote-wrapper">
                <div className="row">
                    <div className="col-12">
                        <p>"Decisions shaped by concrete data are the easiest to make."</p>
                    </div>
                </div>
            </div>
            
        </div>
    </Layout>
)